import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles/AdminDashboard.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { API_URL } from '../config';

interface User {
  id: number;
  username: string;
  email: string;
  created_at: string;
  is_admin: boolean;
}

interface Song {
  id: number;
  title: string;
  uploaded_by: string;
  user_email: string;
  created_at: string;
  is_pinned: boolean;
  pin_title: string | null;
}

interface Comment {
  id: number;
  content: string;
  user_email: string;
  song_title: string;
  created_at: string;
  username: string;
}

const AdminDashboard = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [songs, setSongs] = useState<Song[]>([]);
  const [comments, setComments] = useState<Comment[]>([]);
  const [activeTab, setActiveTab] = useState<'users' | 'songs' | 'comments'>('users');
  const [error, setError] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pinDialogOpen, setPinDialogOpen] = useState(false);
  const [selectedSongId, setSelectedSongId] = useState<number | null>(null);
  const [pinTitle, setPinTitle] = useState('');
  const [dateRange, setDateRange] = useState<{start: Date | null, end: Date | null}>({
    start: null,
    end: null
  });
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);

  const fetchData = async () => {
    try {
      const mysqlUserId = localStorage.getItem('mysqlUserId');
      console.log('Fetching admin data with MySQL ID:', mysqlUserId);

      const [usersRes, songsRes, commentsRes] = await Promise.all([
        axios.get(`${API_URL}/api/admin/users`, {
          params: { adminId: mysqlUserId }
        }),
        axios.get(`${API_URL}/api/admin/songs`, {
          params: { adminId: mysqlUserId }
        }),
        axios.get(`${API_URL}/api/admin/comments`, {
          params: { adminId: mysqlUserId }
        })
      ]);

      setUsers(usersRes.data);
      setSongs(songsRes.data);
      setComments(commentsRes.data);
      setError('');
    } catch (error: any) {
      console.error('Error fetching admin data:', error);
      setError(error.response?.data?.error || 'Failed to fetch data');
    }
  };

  const handleDeleteUser = async (userId: number) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const mysqlUserId = localStorage.getItem('mysqlUserId');
        await axios.delete(`${API_URL}/api/admin/users/${userId}`, {
          params: { adminId: mysqlUserId }
        });
        fetchData();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  const handleDeleteSong = async (songId: number) => {
    if (window.confirm('Are you sure you want to delete this song?')) {
      try {
        const mysqlUserId = localStorage.getItem('mysqlUserId');
        await axios.delete(`${API_URL}/api/admin/songs/${songId}`, {
          params: { adminId: mysqlUserId }
        });
        fetchData();
      } catch (error) {
        console.error('Error deleting song:', error);
      }
    }
  };

  const handleDeleteComment = async (commentId: number) => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      try {
        const mysqlUserId = localStorage.getItem('mysqlUserId');
        await axios.delete(`${API_URL}/api/admin/comments/${commentId}`, {
          params: { adminId: mysqlUserId }
        });
        fetchData();
      } catch (error) {
        console.error('Error deleting comment:', error);
      }
    }
  };

  const handlePinClick = (songId: number) => {
    setSelectedSongId(songId);
    setPinDialogOpen(true);
  };

  const handlePinSubmit = async () => {
    try {
      const mysqlUserId = localStorage.getItem('mysqlUserId');
      await axios.post(`${API_URL}/api/admin/songs/${selectedSongId}/pin`, 
        { pinTitle },
        { params: { adminId: mysqlUserId } }
      );
      fetchData();
      setPinDialogOpen(false);
      setPinTitle('');
      setSelectedSongId(null);
    } catch (error) {
      console.error('Error pinning song:', error);
      alert('Failed to pin song');
    }
  };

  const handleUnpin = async (songId: number) => {
    try {
      const mysqlUserId = localStorage.getItem('mysqlUserId');
      await axios.post(`${API_URL}/api/admin/songs/${songId}/unpin`, 
        {},
        { params: { adminId: mysqlUserId } }
      );
      fetchData();
    } catch (error) {
      console.error('Error unpinning song:', error);
      alert('Failed to unpin song');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Filter function for users
  const filteredUsers = users.filter(user => 
    user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Filter function for songs
  const filteredSongs = songs.filter(song => 
    song.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    song.uploaded_by.toLowerCase().includes(searchTerm.toLowerCase()) ||
    song.user_email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Filter function for comments
  const filteredComments = comments.filter(comment =>
    comment.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
    comment.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    comment.user_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    comment.song_title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDateChange = (type: 'start' | 'end', newValue: Date | null) => {
    setDateRange(prev => ({
      ...prev,
      [type]: newValue
    }));
  };

  const handleApplyDateFilter = async () => {
    try {
      setIsApplyingFilter(true);
      const mysqlUserId = localStorage.getItem('mysqlUserId');
      
      await axios.post(`${API_URL}/api/admin/date-range`, {
        startDate: dateRange.start?.toISOString(),
        endDate: dateRange.end?.toISOString(),
        adminId: mysqlUserId
      });
      
      await fetchData();
      alert('Date filter applied successfully!');
    } catch (error) {
      console.error('Error setting date range:', error);
      setError('Failed to set date range');
    } finally {
      setIsApplyingFilter(false);
    }
  };

  const handleClearDateFilter = async () => {
    try {
      setIsApplyingFilter(true);
      const mysqlUserId = localStorage.getItem('mysqlUserId');
      
      await axios.post(`${API_URL}/api/admin/date-range`, {
        startDate: null,
        endDate: null,
        adminId: mysqlUserId
      });
      
      setDateRange({ start: null, end: null });
      await fetchData();
      alert('Date filter cleared!');
    } catch (error) {
      console.error('Error clearing date range:', error);
      setError('Failed to clear date range');
    } finally {
      setIsApplyingFilter(false);
    }
  };

  return (
    <div className="admin-dashboard-container">
      <div className="admin-dashboard">
        <h2 className="dashboard-title">Admin Dashboard</h2>

        {error && (
          <div className="error-message">{error}</div>
        )}

        <div className="admin-controls">
          <div className="tab-buttons">
            <button 
              onClick={() => setActiveTab('users')}
              className={activeTab === 'users' ? 'active' : ''}
            >
              Users ({filteredUsers.length})
            </button>
            <button 
              onClick={() => setActiveTab('songs')}
              className={activeTab === 'songs' ? 'active' : ''}
            >
              Songs ({filteredSongs.length})
            </button>
            <button 
              onClick={() => setActiveTab('comments')}
              className={activeTab === 'comments' ? 'active' : ''}
            >
              Comments ({filteredComments.length})
            </button>
          </div>

          <div className="search-box">
            <input
              type="text"
              placeholder={`Search ${activeTab}...`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>

          <div className="date-filters" style={{ 
            marginTop: '20px', 
            display: 'flex', 
            gap: '16px',
            alignItems: 'center' 
          }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={dateRange.start}
                onChange={(newValue) => handleDateChange('start', newValue)}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '4px',
                  '& .MuiInputBase-root': {
                    color: 'black',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'rgba(0, 0, 0, 0.7)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.3)',
                  },
                  '& .MuiIconButton-root': {
                    color: 'black',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: 'rgba(0, 0, 0, 0.5)',
                  }
                }}
              />
              <DatePicker
                label="End Date"
                value={dateRange.end}
                onChange={(newValue) => handleDateChange('end', newValue)}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '4px',
                  '& .MuiInputBase-root': {
                    color: 'black',
                  },
                  '& .MuiInputLabel-root': {
                    color: 'rgba(0, 0, 0, 0.7)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.3)',
                  },
                  '& .MuiIconButton-root': {
                    color: 'black',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: 'rgba(0, 0, 0, 0.5)',
                  }
                }}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              onClick={handleApplyDateFilter}
              disabled={isApplyingFilter || (!dateRange.start && !dateRange.end)}
              sx={{
                height: '56px',
                backgroundColor: '#4CAF50',
                '&:hover': {
                  backgroundColor: '#45a049',
                }
              }}
            >
              {isApplyingFilter ? (
                <>
                  <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                  Applying...
                </>
              ) : (
                'Apply Filter'
              )}
            </Button>
            <Button
              variant="outlined"
              onClick={handleClearDateFilter}
              disabled={isApplyingFilter || (!dateRange.start && !dateRange.end)}
              sx={{
                height: '56px',
                color: '#f44336',
                borderColor: '#f44336',
                '&:hover': {
                  borderColor: '#d32f2f',
                  backgroundColor: 'rgba(244, 67, 54, 0.04)',
                }
              }}
            >
              Clear Filter
            </Button>
          </div>
        </div>

        {activeTab === 'users' && (
          <div className="users-table">
            <h3>Users</h3>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Created At</th>
                  <th>Admin</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map(user => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{new Date(user.created_at).toLocaleDateString()}</td>
                    <td>{user.is_admin ? 'Yes' : 'No'}</td>
                    <td>
                      {!user.is_admin && (
                        <button onClick={() => handleDeleteUser(user.id)}>Delete</button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTab === 'songs' && (
          <div className="songs-table">
            <h3>Songs</h3>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Uploaded By</th>
                  <th>User Email</th>
                  <th>Created At</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredSongs.map(song => (
                  <tr key={song.id}>
                    <td>{song.id}</td>
                    <td>{song.title}</td>
                    <td>{song.uploaded_by}</td>
                    <td>{song.user_email}</td>
                    <td>{new Date(song.created_at).toLocaleDateString()}</td>
                    <td>
                      {song.is_pinned ? (
                        <span style={{ color: '#4caf50' }}>
                          🏆 {song.pin_title}
                        </span>
                      ) : '-'}
                    </td>
                    <td>
                      <div style={{ display: 'flex', gap: '8px' }}>
                        {song.is_pinned ? (
                          <button 
                            onClick={() => handleUnpin(song.id)}
                            style={{ backgroundColor: '#ff9800' }}
                          >
                            Unpin
                          </button>
                        ) : (
                          <button 
                            onClick={() => handlePinClick(song.id)}
                            style={{ backgroundColor: '#4caf50' }}
                          >
                            Pin
                          </button>
                        )}
                        <button 
                          onClick={() => handleDeleteSong(song.id)}
                          style={{ backgroundColor: '#f44336' }}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {activeTab === 'comments' && (
          <div className="comments-table">
            <h3>Comments</h3>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Comment</th>
                  <th>Username</th>
                  <th>User Email</th>
                  <th>Song</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredComments.map(comment => (
                  <tr key={comment.id}>
                    <td>{comment.id}</td>
                    <td>{comment.content}</td>
                    <td>{comment.username}</td>
                    <td>{comment.user_email}</td>
                    <td>{comment.song_title}</td>
                    <td>{new Date(comment.created_at).toLocaleDateString()}</td>
                    <td>
                      <button onClick={() => handleDeleteComment(comment.id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Dialog open={pinDialogOpen} onClose={() => setPinDialogOpen(false)}>
        <DialogTitle>Pin Song</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Pin Title (e.g., Weekly Winner - Week 32)"
            fullWidth
            value={pinTitle}
            onChange={(e) => setPinTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPinDialogOpen(false)}>Cancel</Button>
          <Button onClick={handlePinSubmit} variant="contained" color="primary">
            Pin
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminDashboard; 