import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import axios from 'axios';
import '../styles/Auth.css';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Firebase UID:', userCredential.user.uid);

      const response = await axios.get(`${API_URL}/api/users/byFirebaseUid/${userCredential.user.uid}`);
      console.log('MySQL user data:', response.data);

      if (response.data && response.data.id) {
        localStorage.setItem('mysqlUserId', response.data.id.toString());
        console.log('Stored MySQL ID:', response.data.id);
      } else {
        throw new Error('Failed to get MySQL user ID');
      }

      navigate('/');
    } catch (err: any) {
      console.error('Login error:', err);
      setError(err.message || 'Failed to login');
    }
  };

  const handlePasswordReset = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!email) {
      setError('Please enter your email address');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
      setError('');
    } catch (err: any) {
      console.error('Password reset error:', err);
      setError(err.message || 'Failed to send password reset email');
    }
  };

  return (
    <div className="Auth-container">
      <form className="Auth-form" onSubmit={handleSubmit}>
        <h2>Login</h2>
        {error && <p style={{ color: 'red', fontSize: '0.8em' }}>{error}</p>}
        {resetSent && (
          <p style={{ color: 'green', fontSize: '0.8em' }}>
            Password reset email sent! Check your inbox.
          </p>
        )}
        <input 
          type="email" 
          placeholder="Email" 
          required 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input 
          type="password" 
          placeholder="Password" 
          required 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
        <button 
          onClick={handlePasswordReset}
          style={{
            background: 'none',
            border: 'none',
            color: '#FE6B8B',
            cursor: 'pointer',
            fontSize: '0.9em',
            marginTop: '10px',
            textDecoration: 'underline'
          }}
        >
          Forgot Password?
        </button>
      </form>
    </div>
  );
}

export default Login;
