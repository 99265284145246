import React, { useState } from 'react';
import { Slider } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import { styled } from '@mui/system';

const VolumeContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  background: 'rgba(255, 255, 255, 0.1)',
  padding: '10px 16px',
  borderRadius: '20px',
  marginLeft: '20px',
  height: '21px',
});

const VolumeSlider = styled(Slider)({
  width: '80px',
  color: '#c084fc',
  '& .MuiSlider-track': {
    background: 'linear-gradient(45deg, #c084fc, #db2777)',
  },
  '& .MuiSlider-thumb': {
    width: 14,
    height: 14,
    backgroundColor: '#fff',
    '&:hover, &.Mui-focusVisible': {
      boxShadow: '0 0 0 8px rgba(192, 132, 252, 0.16)',
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
  }
});

const VolumeControl = () => {
  const [volume, setVolume] = useState(1);
  const [prevVolume, setPrevVolume] = useState(1);

  const handleVolumeChange = (_: Event, newValue: number | number[]) => {
    const newVolume = newValue as number;
    setVolume(newVolume);
    if (newVolume > 0) {
      setPrevVolume(newVolume);
    }
    // Update all wavesurfer instances
    window.dispatchEvent(new CustomEvent('volume-change', { detail: { volume: newVolume } }));
  };

  const handleMuteToggle = () => {
    const newVolume = volume > 0 ? 0 : prevVolume;
    setVolume(newVolume);
    window.dispatchEvent(new CustomEvent('volume-change', { detail: { volume: newVolume } }));
  };

  const VolumeIcon = () => {
    if (volume === 0) return <VolumeMuteIcon sx={{ color: 'white', cursor: 'pointer' }} />;
    if (volume < 0.5) return <VolumeDownIcon sx={{ color: 'white', cursor: 'pointer' }} />;
    return <VolumeUpIcon sx={{ color: 'white', cursor: 'pointer' }} />;
  };

  return (
    <div className="volume-control">
      <VolumeContainer>
        <div onClick={handleMuteToggle}>
          <VolumeIcon />
        </div>
        <VolumeSlider
          value={volume}
          onChange={handleVolumeChange}
          min={0}
          max={1}
          step={0.01}
          aria-label="Volume"
        />
      </VolumeContainer>
    </div>
  );
};

export default VolumeControl; 