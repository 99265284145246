import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import '../styles/Auth.css';
import { API_URL } from '../config';

const Profile: React.FC = () => {
  const [profilePhoto, setProfilePhoto] = useState<string | null>(null);
  const [instagram, setInstagram] = useState('');
  const [spotify, setSpotify] = useState('');
  const [username, setUsername] = useState('');
  const [instagramError, setInstagramError] = useState('');
  const [spotifyError, setSpotifyError] = useState('');

  const validateInstagramUrl = (url: string) => {
    if (!url) return true; // Empty is OK
    const instagramRegex = /^https?:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9_\.]+\/?$/;
    return instagramRegex.test(url);
  };

  const validateSpotifyUrl = (url: string) => {
    if (!url) return true; // Empty is OK
    const spotifyRegex = /^https?:\/\/(open\.)?spotify\.com\/(artist|user|playlist)\/[a-zA-Z0-9]+(\?.*)?$/;
    return spotifyRegex.test(url);
  };

  const handleInstagramChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInstagram(value);
    if (!validateInstagramUrl(value)) {
      setInstagramError('Please enter a valid Instagram profile URL (e.g., https://instagram.com/username)');
    } else {
      setInstagramError('');
    }
  };

  const handleSpotifyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSpotify(value);
    if (!validateSpotifyUrl(value)) {
      setSpotifyError('Please enter a valid Spotify URL (e.g., https://open.spotify.com/artist/...)');
    } else {
      setSpotifyError('');
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        try {
          const response = await fetch(`${API_URL}/api/users/${currentUser.uid}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const userData = await response.json();
          console.log('Fetched user data:', userData); // Debug log
          setUsername(userData.username);
          setInstagram(userData.instagram_url || '');
          setSpotify(userData.spotify_url || '');
          if (userData.profile_picture_url) {
            setProfilePhoto(userData.profile_picture_url);
          }
        } catch (err) {
          console.error('Error fetching user data:', err);
        }
      }
    };

    fetchUserData();
  }, []);

  const handlePhotoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      // Show preview immediately
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePhoto(reader.result as string);
      };
      reader.readAsDataURL(file);

      // Upload to server
      try {
        const formData = new FormData();
        formData.append('profilePicture', file);

        const response = await fetch(
          `${API_URL}/api/users/${currentUser.uid}/profile-picture`,
          {
            method: 'POST',
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error('Failed to upload profile picture');
        }

        const data = await response.json();
        setProfilePhoto(data.imageUrl);
      } catch (err) {
        console.error('Error uploading profile picture:', err);
        // You might want to add error handling UI here
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    if (!validateInstagramUrl(instagram) || !validateSpotifyUrl(spotify)) {
      alert('Please enter valid social media URLs');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/users/${currentUser.uid}/social-links`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          instagram_url: instagram,
          spotify_url: spotify,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update social links');
      }

      alert('Profile updated successfully!');
    } catch (err) {
      console.error('Error updating profile:', err);
      alert('Failed to update profile');
    }
  };

  return (
    <div className="Auth-container">
      <form className="Auth-form" onSubmit={handleSubmit}>
        <div className="profile-section">
          <h2>{username}</h2>
          <div className="profile-photo-container">
            {profilePhoto ? (
              <img src={profilePhoto} alt="Profile" className="profile-photo" />
            ) : (
              <div className="profile-photo-placeholder"></div>
            )}
            <input 
              type="file" 
              accept="image/*" 
              onChange={handlePhotoUpload} 
              className="profile-photo-input"
              id="profile-photo-input"
            />
            <label htmlFor="profile-photo-input" className="profile-photo-label">
              {/* Optional: Add a small camera icon or edit text here */}
            </label>
          </div>
        </div>
        <div style={{ width: '100%', marginBottom: '1rem' }}>
          <input
            type="url"
            placeholder="Instagram URL (e.g., https://instagram.com/username)"
            value={instagram}
            onChange={handleInstagramChange}
            style={{
              width: '100%',
              padding: '8px',
              marginBottom: '4px',
              borderColor: instagramError ? '#ff4444' : undefined
            }}
          />
          {instagramError && (
            <div style={{ color: '#ff4444', fontSize: '0.8rem' }}>
              {instagramError}
            </div>
          )}
        </div>

        <div style={{ width: '100%', marginBottom: '1rem' }}>
          <input
            type="url"
            placeholder="Spotify URL (e.g., https://open.spotify.com/artist/...)"
            value={spotify}
            onChange={handleSpotifyChange}
            style={{
              width: '100%',
              padding: '8px',
              marginBottom: '4px',
              borderColor: spotifyError ? '#ff4444' : undefined
            }}
          />
          {spotifyError && (
            <div style={{ color: '#ff4444', fontSize: '0.8rem' }}>
              {spotifyError}
            </div>
          )}
        </div>

        <button 
          type="submit"
          disabled={!!instagramError || !!spotifyError}
        >
          Save Profile
        </button>
      </form>
    </div>
  );
};

export default Profile;
