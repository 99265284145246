import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, CardActions, Collapse, TextField, Avatar, Button, Divider } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { auth } from '../../firebase';
import WaveSurfer from 'wavesurfer.js';
import { useAudio } from '../../context/AudioContext';
import { FaInstagram, FaSpotify, FaSoundcloud } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import { API_URL } from '../../config';

interface SongPostProps {
  id: number;
  title: string;
  artist: string;
  profileImage: string;
  upvotes: number;
  commentCount: number;
  userVote?: number;
  audioUrl?: string;
  isPinned?: boolean;
  pinTitle?: string | null;
  instagram_url?: string;
  spotify_url?: string;
  soundcloud_url?: string;
}

interface Comment {
  id: number;
  userId: number;
  username: string;
  profileImage: string;
  content: string;
  timestamp: string;
}

interface UserProfile {
  id: number;
  username: string;
  profile_picture_url: string;
}

const SocialLinks: React.FC<{
  instagram?: string;
  spotify?: string;
  soundcloud?: string;
}> = ({ instagram, spotify, soundcloud }) => {
  if (!instagram && !spotify && !soundcloud) return null;

  return (
    <div style={{ 
      display: 'flex', 
      gap: '8px',
      marginLeft: 'auto' 
    }}>
      {instagram && (
        <IconButton
          href={instagram}
          target="_blank"
          rel="noopener noreferrer"
          size="small"
          sx={{
            color: 'rgba(255, 255, 255, 0.7)',
            '&:hover': {
              color: '#E1306C'  // Instagram color
            }
          }}
        >
          <FaInstagram />
        </IconButton>
      )}
      {spotify && (
        <IconButton
          href={spotify}
          target="_blank"
          rel="noopener noreferrer"
          size="small"
          sx={{
            color: 'rgba(255, 255, 255, 0.7)',
            '&:hover': {
              color: '#1DB954'  // Spotify color
            }
          }}
        >
          <FaSpotify />
        </IconButton>
      )}
      {soundcloud && (
        <IconButton
          href={soundcloud}
          target="_blank"
          rel="noopener noreferrer"
          size="small"
          sx={{
            color: 'rgba(255, 255, 255, 0.7)',
            '&:hover': {
              color: '#FF3300'  // SoundCloud color
            }
          }}
        >
          <FaSoundcloud />
        </IconButton>
      )}
    </div>
  );
};

const SongPost: React.FC<SongPostProps> = ({
  id,
  title,
  artist,
  profileImage,
  upvotes: initialUpvotes,
  commentCount,
  userVote: initialUserVote = 0,
  audioUrl,
  isPinned,
  pinTitle,
  instagram_url,
  spotify_url,
  soundcloud_url,
}) => {
  console.log('Social URLs:', { instagram_url, spotify_url, soundcloud_url });

  const [upvotes, setUpvotes] = useState(initialUpvotes);
  const [userVote, setUserVote] = useState(initialUserVote);
  const [isPlaying, setIsPlaying] = useState(false);
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const { currentlyPlaying, setCurrentlyPlaying } = useAudio();
  const [expanded, setExpanded] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState<Comment[]>([]);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    setUpvotes(initialUpvotes);
    setUserVote(initialUserVote);
  }, [initialUpvotes, initialUserVote]);

  useEffect(() => {
    if (waveformRef.current && audioUrl) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: 'rgba(255, 255, 255, 0.3)',
        progressColor: 'rgba(255, 255, 255, 0.8)',
        cursorColor: 'transparent',
        barWidth: 2,
        barGap: 3,
        height: 60,
        url: audioUrl,
      });

      wavesurferRef.current.on('play', () => {
        setIsPlaying(true);
        setCurrentlyPlaying(id);
      });
      
      wavesurferRef.current.on('pause', () => {
        setIsPlaying(false);
        setCurrentlyPlaying(null);
      });
      
      wavesurferRef.current.on('finish', () => {
        setIsPlaying(false);
        setCurrentlyPlaying(null);
      });

      // Add volume change listener
      const handleVolumeChange = (e: CustomEvent) => {
        if (wavesurferRef.current) {
          wavesurferRef.current.setVolume(e.detail.volume);
        }
      };

      window.addEventListener('volume-change', handleVolumeChange as EventListener);

      return () => {
        wavesurferRef.current?.destroy();
        window.removeEventListener('volume-change', handleVolumeChange as EventListener);
      };
    }
  }, [audioUrl, id, setCurrentlyPlaying]);

  useEffect(() => {
    if (currentlyPlaying !== null && currentlyPlaying !== id && isPlaying) {
      wavesurferRef.current?.pause();
      setIsPlaying(false);
    }
  }, [currentlyPlaying, id, isPlaying]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (auth.currentUser) {
        try {
          const response = await fetch(`${API_URL}/api/users/${auth.currentUser.uid}`);
          if (response.ok) {
            const userData = await response.json();
            setUserProfile(userData);
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, []);

  const handleVote = async (voteType: number) => {
    const user = auth.currentUser;
    if (!user) {
      alert('Please log in to vote');
      return;
    }

    try {
      // Store current state for potential rollback
      const previousUpvotes = upvotes;
      const previousUserVote = userVote;

      // Optimistic update
      if (userVote === 1) {
        setUpvotes(prev => Math.max(0, prev - 1));
        setUserVote(0);
      } else {
        setUpvotes(prev => prev + 1);
        setUserVote(1);
      }

      const token = await user.getIdToken();
      const response = await fetch(`${API_URL}/api/songs/${id}/vote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ voteType: 1 })
      });

      if (!response.ok) {
        setUpvotes(previousUpvotes);
        setUserVote(previousUserVote);
        throw new Error('Failed to vote');
      }

      const data = await response.json();
      console.log('Vote response:', data);
      
      if (typeof data.upvotes === 'number') {
        setUpvotes(data.upvotes);
      }
      if (typeof data.userVote === 'number') {
        setUserVote(data.userVote);
      }

    } catch (error) {
      console.error('Error voting:', error);
      alert('Failed to vote. Please try again.');
    }
  };

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      if (isPlaying) {
        wavesurferRef.current.pause();
        setCurrentlyPlaying(null);
      } else {
        if (currentlyPlaying !== null && currentlyPlaying !== id) {
          const event = new CustomEvent('pause-all-audio', { detail: { exceptId: id } });
          window.dispatchEvent(event);
        }
        wavesurferRef.current.seekTo(0);
        wavesurferRef.current.play();
        setCurrentlyPlaying(id);
      }
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (!expanded) {
      // Fetch comments when expanding
      fetchComments();
    }
  };

  const fetchComments = async () => {
    try {
      const response = await fetch(`${API_URL}/api/songs/${id}/comments`);
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleAddComment = async () => {
    const user = auth.currentUser;
    if (!commentText.trim() || !user) {
      alert('Please log in and enter a comment');
      return;
    }

    try {
      const token = await user.getIdToken();
      const response = await fetch(`${API_URL}/api/songs/${id}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          content: commentText
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Comment error:', errorData);
        throw new Error(errorData.error || 'Failed to post comment');
      }

      const newComment = await response.json();
      console.log('New comment:', newComment);

      const completeComment: Comment = {
        id: newComment.id,
        userId: newComment.user_id,
        username: newComment.username,
        profileImage: newComment.profile_picture_url || userProfile?.profile_picture_url || '',
        content: newComment.content,
        timestamp: newComment.created_at
      };
      
      setComments([completeComment, ...comments]);
      setCommentText('');
    } catch (error) {
      console.error('Error posting comment:', error);
      alert('Failed to post comment. Please try again.');
    }
  };

  return (
    <Card
      component="div"
      sx={{ 
        width: '100%', 
        margin: '0 auto',
        background: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        color: 'white',
        borderRadius: '12px',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        position: 'relative',
        overflow: 'visible',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
        },
        ...(isPinned && {
          '&::before': {
            content: '""',
            position: 'absolute',
            top: -2,
            right: -2,
            bottom: -2,
            left: -2,
            border: '2px solid transparent',
            borderRadius: '14px',
            background: 'linear-gradient(45deg, #ffd700, #ffa500, #ff8c00, #daa520, #ffd700) border-box',
            WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
            WebkitMaskComposite: 'destination-out',
            maskComposite: 'exclude',
            backgroundSize: '200% 200%',
            animation: 'shimmer 3s linear infinite',
            pointerEvents: 'none',
            zIndex: 0,
          },
          '@keyframes shimmer': {
            '0%': {
              backgroundPosition: '0% 50%',
            },
            '50%': {
              backgroundPosition: '100% 50%',
            },
            '100%': {
              backgroundPosition: '0% 50%',
            },
          },
        }),
      }}>
      <CardContent sx={{
        position: 'relative',
        padding: '20px',
        minHeight: '160px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/* Pin Title */}
        {isPinned && pinTitle ? (
          <div style={{
            background: 'linear-gradient(45deg, #c084fc, #db2777)',
            padding: '8px 16px',
            borderRadius: '4px',
            marginBottom: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 3,
          }}>
            <span style={{ 
              fontWeight: 'bold',
              fontSize: '14px',
              color: 'white',
            }}>
              🏆 {pinTitle}
            </span>
          </div>
        ) : null}
        
        <div style={{
          display: 'flex',
          alignItems: 'flex-start',
          marginBottom: '20px',
          zIndex: 2,
        }}>
          <Button 
            variant="contained"
            onClick={handlePlayPause}
            sx={{
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              background: 'linear-gradient(45deg, #c084fc, #db2777)',
              '&:hover': {
                background: 'linear-gradient(45deg, #db2777, #c084fc)',
              },
              minWidth: 'unset',
              padding: 0,
              marginRight: '15px',
            }}
          >
            {isPlaying ? (
              <PauseIcon sx={{ fontSize: 30 }} />
            ) : (
              <PlayArrowIcon sx={{ fontSize: 30 }} />
            )}
          </Button>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar 
              src={profileImage} 
              alt={artist}
              sx={{ 
                width: 48,  // Match play button size
                height: 48, // Match play button size
                marginRight: '15px'
              }}
            >
              {artist[0]}
            </Avatar>
            <div style={{ flex: 1 }}>
              <div style={{ 
                fontSize: '16px',
                color: 'rgba(255, 255, 255, 0.7)',
                marginBottom: '4px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '100%'
              }}>
                {artist.length > 50 ? `${artist.slice(0, 50)}...` : artist}
              </div>
              <div style={{ 
                fontSize: '26px',
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '100%'
              }}>
                {title.length > 45 ? `${title.slice(0, 45)}...` : title}
              </div>
            </div>
          </div>
        </div>

        <div 
          ref={waveformRef} 
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            height: '80px',
            padding: '0 20px'
          }}
        />
      </CardContent>

      <CardActions sx={{ 
        justifyContent: 'space-between',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        padding: '8px 16px'
      }}>
        <div className="vote-section" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <IconButton
            onClick={() => handleVote(1)}
            sx={{
              color: userVote === 1 ? '#4CAF50' : 'rgba(255, 255, 255, 0.7)',
              '&:hover': {
                color: '#4CAF50'
              }
            }}
          >
            <ThumbUpIcon />
          </IconButton>
          <span style={{ color: 'white' }}>{upvotes || 0}</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <SocialLinks
            instagram={instagram_url}
            spotify={spotify_url}
            soundcloud={soundcloud_url}
          />
          <Button 
            startIcon={<CommentIcon />} 
            onClick={handleExpandClick}
            size="small"
            sx={{ 
              color: 'white',
              '&:hover': {
                color: 'rgba(255, 255, 255, 0.8)',
              }
            }}
          >
            {commentCount > 0 ? commentCount : ''}
          </Button>
        </div>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
        <CardContent sx={{ 
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          padding: '20px'
        }}>
          {/* Comment Input */}
          <div style={{ 
            display: 'flex', 
            gap: '15px', 
            marginBottom: '20px' 
          }}>
            <Avatar 
              src={userProfile?.profile_picture_url}
              alt={userProfile?.username || 'User'}
              sx={{ 
                width: 40, 
                height: 40,
                bgcolor: 'rgba(255, 255, 255, 0.1)'
              }}
            >
              {userProfile?.username?.[0]?.toUpperCase() || auth.currentUser?.email?.[0]?.toUpperCase()}
            </Avatar>
            <TextField
              fullWidth
              multiline
              rows={2}
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              placeholder="Add a comment..."
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.2)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.3)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#c084fc',
                  },
                },
                '& .MuiInputBase-input::placeholder': {
                  color: 'rgba(255, 255, 255, 0.5)',
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleAddComment}
              disabled={!commentText.trim() || !auth.currentUser}
              sx={{
                background: 'linear-gradient(45deg, #c084fc, #db2777)',
                '&:hover': {
                  background: 'linear-gradient(45deg, #db2777, #c084fc)',
                },
                '&.Mui-disabled': {
                  background: 'rgba(255, 255, 255, 0.12)',
                }
              }}
            >
              Post
            </Button>
          </div>

          {/* Comments List */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {comments.map((comment) => (
              <div 
                key={comment.id} 
                style={{ 
                  display: 'flex', 
                  gap: '15px' 
                }}
              >
                <Avatar 
                  src={comment.profileImage} 
                  sx={{ width: 40, height: 40 }}
                >
                  {comment.username[0]}
                </Avatar>
                <div style={{ flex: 1 }}>
                  <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    marginBottom: '4px' 
                  }}>
                    <span style={{ 
                      fontWeight: 'bold',
                      color: 'rgba(255, 255, 255, 0.9)' 
                    }}>
                      {comment.username}
                    </span>
                    <span style={{ 
                      fontSize: '0.8rem',
                      color: 'rgba(255, 255, 255, 0.5)' 
                    }}>
                      {comment.timestamp}
                    </span>
                  </div>
                  <div style={{ 
                    color: 'rgba(255, 255, 255, 0.8)'
                  }}>
                    {comment.content}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default SongPost;
