import React, { useEffect, useState, useRef, useCallback } from 'react';
import SongPost from '../components/SongPost/SongPost';
import { auth } from '../firebase';
import { Button, ButtonGroup, CircularProgress } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { API_URL } from '../config';

interface Song {
  id: number;
  title: string;
  artist: string;
  audioUrl: string;
  upvotes: number;
  commentCount: number;
  userVote?: number;
  is_pinned: boolean;
  pin_title: string | null;
  profileImage?: string;
  instagram_url?: string;
  spotify_url?: string;
  soundcloud_url?: string;
}

type SortType = 'trending' | 'new';

function Home() {
  const [songs, setSongs] = useState<Song[]>([]);
  const [sortType, setSortType] = useState<SortType>('trending');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [dateFilterInfo, setDateFilterInfo] = useState<{
    active: boolean;
    startDate?: string;
    endDate?: string;
    total: number;
  } | null>(null);
  const observer = useRef<IntersectionObserver>();

  const lastSongElementRef = useCallback((node: HTMLDivElement) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const fetchSongs = async (pageNum: number, isNewSort = false) => {
    try {
      setLoading(true);
      const user = auth.currentUser;
      let userId = localStorage.getItem('mysqlUserId') || null;

      const url = `${API_URL}/api/songs?userId=${userId || ''}&sort=${sortType}&page=${pageNum}&limit=10`;
      console.log('Fetching songs from:', url);

      const response = await fetch(url);
      
      if (!response.ok) {
        throw new Error('Failed to fetch songs');
      }
      
      const data = await response.json();
      console.log('Received songs data:', data);
      
      setSongs(prev => isNewSort ? data.songs : [...prev, ...data.songs]);
      setHasMore(data.hasMore);
      
      if (data.dateFilter) {
        setDateFilterInfo({
          active: true,
          startDate: new Date(data.dateFilter.startDate).toLocaleDateString(),
          endDate: new Date(data.dateFilter.endDate).toLocaleDateString(),
          total: data.total
        });
      } else {
        setDateFilterInfo(null);
      }

      if (!initialized) setInitialized(true);
    } catch (error) {
      console.error('Error fetching songs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      if (!initialized) {
        setPage(1);
        setSongs([]);
        fetchSongs(1, true);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (initialized) {
      setPage(1);
      setSongs([]);
      fetchSongs(1, true);
    }
  }, [sortType]);

  useEffect(() => {
    if (initialized && page > 1) {
      fetchSongs(page);
    }
  }, [page]);

  return (
    <div className="Home" style={{ padding: '20px' }}>
      <div style={{ 
        maxWidth: '800px',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
      }}>
        <ButtonGroup 
          variant="contained" 
          sx={{
            alignSelf: 'center',
            background: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(10px)',
            borderRadius: '12px',
            overflow: 'hidden',
            '& .MuiButton-root': {
              border: 'none',
              padding: '10px 20px',
              color: 'white',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
              '&.active': {
                background: 'linear-gradient(45deg, #c084fc, #db2777)',
              }
            }
          }}
        >
          <Button
            startIcon={<TrendingUpIcon />}
            className={sortType === 'trending' ? 'active' : ''}
            onClick={() => setSortType('trending')}
          >
            Trending
          </Button>
          <Button
            startIcon={<AccessTimeIcon />}
            className={sortType === 'new' ? 'active' : ''}
            onClick={() => setSortType('new')}
          >
            New
          </Button>
        </ButtonGroup>

        {dateFilterInfo?.active && (
          <div style={{
            backgroundColor: 'rgba(76, 175, 80, 0.1)',
            color: '#4CAF50',
            padding: '8px 16px',
            borderRadius: '4px',
            marginBottom: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px'
          }}>
            <span>Showing songs from {dateFilterInfo.startDate} to {dateFilterInfo.endDate} ({dateFilterInfo.total} songs)</span>
          </div>
        )}

        {songs.map((song, index) => (
          <div
            key={song.id}
            ref={index === songs.length - 1 ? lastSongElementRef : undefined}
          >
            <SongPost 
              id={song.id}
              title={song.title}
              artist={song.artist}
              profileImage={song.profileImage || ''}
              upvotes={song.upvotes}
              commentCount={song.commentCount}
              userVote={song.userVote}
              audioUrl={song.audioUrl}
              isPinned={song.is_pinned}
              pinTitle={song.pin_title}
              instagram_url={song.instagram_url}
              spotify_url={song.spotify_url}
              soundcloud_url={song.soundcloud_url}
            />
          </div>
        ))}

        {loading && (
          <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            padding: '20px' 
          }}>
            <CircularProgress sx={{ color: 'white' }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
