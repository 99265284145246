import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDaxOt4u5ul-ZRkCkQFLIJye8yZxKmlSrc",
  authDomain: "wavsnack.firebaseapp.com",
  projectId: "wavsnack",
  storageBucket: "wavsnack.appspot.com",
  messagingSenderId: "889076476292",
  appId: "1:889076476292:web:a391a41983362cea4595d1",
  measurementId: "G-BHG2C5X6G5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

console.log('Firebase initialized successfully');

export { auth };
export default app;
