import React from 'react';
import '../styles/Terms.css';
import { useNavigate } from 'react-router-dom';

function Terms() {
  const navigate = useNavigate();

  return (
    <div className="terms-page">
      <div className="terms-content">
        <h1>User Agreement for WAVsnack</h1>
        
        <p>Effective Date: October 31, 2024</p>

        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing or using WAVsnack (the "Service"), you agree to be bound by these terms and conditions ("Terms"). If you do not agree to these Terms, you may not use the Service.</p>
        </section>

        <section>
          <h2>2. User Responsibilities</h2>
          <p>You must be at least 18 years old to use this Service.</p>
          <p>You are solely responsible for the content you upload, share, or create using the Service ("User Content"). You represent and warrant that you have all necessary rights, licenses, and permissions to upload and share your User Content.</p>
        </section>

        <section>
          <h2>3. Prohibited Content</h2>
          <p>You agree not to upload, post, or share any content that:</p>
          <ul>
            <li>Infringes the intellectual property rights of any party, including copyright, trademark, and patent rights.</li>
            <li>Is defamatory, obscene, abusive, harassing, or otherwise unlawful.</li>
            <li>Contains viruses or other malicious code.</li>
          </ul>
        </section>

        <section>
          <h2>4. DMCA Compliance</h2>
          <p>WavSnack complies with the Digital Millennium Copyright Act (DMCA). If you believe that your copyright has been infringed, please submit a written notice to our designated agent:</p>
          <p>[Insert Contact Information for DMCA Notices]</p>
          <p>Upon receiving a valid DMCA notice, we will take appropriate action, including removing or disabling access to the infringing content.</p>
        </section>

        <section>
          <h2>5. Indemnification</h2>
          <p>You agree to indemnify, defend, and hold harmless WAVsnack, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in any way connected to your User Content or your violation of these Terms.</p>
        </section>

        <section>
          <h2>6. Termination</h2>
          <p>We reserve the right to terminate or suspend your access to the Service at our discretion, without notice, for any reason, including but not limited to a violation of these Terms.</p>
        </section>

        <section>
          <h2>7. Disclaimer of Warranties</h2>
          <p>The Service is provided on an "as-is" and "as-available" basis. WAVsnack makes no representations or warranties of any kind, express or implied, regarding the operation of the Service or the information, content, materials, or products included.</p>
        </section>

        <section>
          <h2>8. Limitation of Liability</h2>
          <p>To the fullest extent permitted by law, WAVsnack shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the Service.</p>
        </section>

        <section>
          <h2>9. Changes to Terms</h2>
          <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.</p>
        </section>

        <section>
          <h2>10. Governing Law</h2>
          <p>These Terms shall be governed by and construed in accordance with the laws of the United States, without regard to its conflict of law principles.</p>
        </section>

        <section>
          <h2>11. Contact Information</h2>
          <p>If you have any questions about these Terms, please contact us at:</p>
          <p>[contact@wavsnack.com]</p>
        </section>

        <div className="terms-button-container">
          <button 
            className="back-button"
            onClick={() => navigate('/signup')}
          >
            Back to Signup
          </button>
        </div>
      </div>
    </div>
  );
}

export default Terms; 