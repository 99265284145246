import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Profile from './pages/Profile';
import Upload from './pages/Upload';
import AdminDashboard from './pages/AdminDashboard';
import Terms from './pages/Terms';
import { initializeApp } from 'firebase/app';
import { onAuthStateChanged, signOut, User } from 'firebase/auth';
import { auth } from './firebase';
import { AudioProvider } from './context/AudioContext';
import axios from 'axios';
import VolumeControl from './components/VolumeControl';
import { API_URL } from './config';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
initializeApp(firebaseConfig);

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      
      if (currentUser) {
        try {
          // Get MySQL user data using Firebase UID
          const response = await axios.get(`${API_URL}/api/users/byFirebaseUid/${currentUser.uid}`);
          setIsAdmin(response.data.is_admin === 1);
          
          // Store MySQL user ID in localStorage if not already there
          if (response.data.id) {
            localStorage.setItem('mysqlUserId', response.data.id.toString());
          }
        } catch (error) {
          console.error('Error checking admin status:', error);
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('mysqlUserId'); // Clear MySQL user ID on logout
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <AudioProvider>
      <Router>
        <div className="App">
          <header className="App-header">
            <Link to="/" className="App-title-link">
              <h1 className="App-title">WAVsnack</h1>
            </Link>
            <div className="header-right">
              <VolumeControl />
              <div className="Auth-buttons">
                {user ? (
                  <>
                    <Link to="/upload" className="Auth-button">Upload</Link>
                    <Link to="/profile" className="Auth-button">Profile</Link>
                    {isAdmin && (
                      <Link to="/admin" className="Auth-button">Admin</Link>
                    )}
                    <button onClick={handleLogout} className="Auth-button">Logout</button>
                  </>
                ) : (
                  <>
                    <Link to="/login" className="Auth-button">Login</Link>
                    <Link to="/signup" className="Auth-button">Signup</Link>
                  </>
                )}
              </div>
            </div>
          </header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={user ? <Navigate to="/profile" /> : <Login />} />
            <Route path="/signup" element={user ? <Navigate to="/" /> : <Signup />} />
            <Route path="/profile" element={user ? <Profile /> : <Navigate to="/login" />} />
            <Route path="/upload" element={user ? <Upload /> : <Navigate to="/login" />} />
            <Route 
              path="/admin" 
              element={user && isAdmin ? <AdminDashboard /> : <Navigate to="/" />} 
            />
            <Route path="/terms" element={<Terms />} />
          </Routes>
        </div>
      </Router>
    </AudioProvider>
  );
}

export default App;
