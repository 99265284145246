import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import WaveSurfer from 'wavesurfer.js';
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import '../styles/Upload.css';
import { styled } from '@mui/system';
import { API_URL } from '../config';

interface TrimmerPosition {
  start: number;
  end: number;
}

interface DragState {
  isDragging: boolean;
  startX: number;
  initialStart: number;
  initialEnd: number;
  type: 'handle' | 'region' | null;
}

const TrimmerOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  background: 'rgba(192, 132, 252, 0.2)', // Purple tint
  border: '2px solid rgba(192, 132, 252, 0.5)',
  cursor: 'grab',
  '&:active': {
    cursor: 'grabbing',
  },
}));

const TrimmerHandle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '4px',
  height: '100%',
  background: 'rgba(192, 132, 252, 0.8)',
  cursor: 'col-resize',
  zIndex: 3,
  '&:hover': {
    background: 'rgba(219, 39, 119, 0.8)', // Pink on hover
  },
}));

async function trimAudio(audioFile: File, startTime: number, endTime: number): Promise<Blob> {
  const audioContext = new AudioContext();
  
  // Read and decode the file
  const arrayBuffer = await audioFile.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
  // Calculate samples
  const sampleRate = audioBuffer.sampleRate;
  const startSample = Math.floor(startTime * sampleRate);
  const endSample = Math.floor(endTime * sampleRate);
  const frameCount = endSample - startSample;

  // Create trimmed buffer
  const trimmedBuffer = audioContext.createBuffer(
    audioBuffer.numberOfChannels,
    frameCount,
    sampleRate
  );

  // Copy the trimmed portion directly
  for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
    const channelData = audioBuffer.getChannelData(channel);
    trimmedBuffer.copyToChannel(
      channelData.slice(startSample, endSample),
      channel
    );
  }

  // Convert to WAV blob directly
  const offlineContext = new OfflineAudioContext(
    audioBuffer.numberOfChannels,
    frameCount,
    sampleRate
  );
  
  const source = offlineContext.createBufferSource();
  source.buffer = trimmedBuffer;
  source.connect(offlineContext.destination);
  source.start();

  const renderedBuffer = await offlineContext.startRendering();
  
  // Convert to WAV
  const wav = audioBufferToWav(renderedBuffer);
  return new Blob([wav], { type: 'audio/wav' });
}

// Helper function to convert AudioBuffer to WAV format
function audioBufferToWav(buffer: AudioBuffer): ArrayBuffer {
  const numChannels = buffer.numberOfChannels;
  const sampleRate = buffer.sampleRate;
  const format = 1; // PCM
  const bitDepth = 16;
  
  const bytesPerSample = bitDepth / 8;
  const blockAlign = numChannels * bytesPerSample;
  
  const dataLength = buffer.length * blockAlign;
  const bufferLength = 44 + dataLength;
  
  const arrayBuffer = new ArrayBuffer(bufferLength);
  const view = new DataView(arrayBuffer);
  
  // WAV header
  writeString(view, 0, 'RIFF');
  view.setUint32(4, 36 + dataLength, true);
  writeString(view, 8, 'WAVE');
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, format, true);
  view.setUint16(22, numChannels, true);
  view.setUint32(24, sampleRate, true);
  view.setUint32(28, sampleRate * blockAlign, true);
  view.setUint16(32, blockAlign, true);
  view.setUint16(34, bitDepth, true);
  writeString(view, 36, 'data');
  view.setUint32(40, dataLength, true);

  // Write audio data
  const offset = 44;
  const channels = [];
  for (let i = 0; i < numChannels; i++) {
    channels.push(buffer.getChannelData(i));
  }

  for (let i = 0; i < buffer.length; i++) {
    for (let channel = 0; channel < numChannels; channel++) {
      const sample = Math.max(-1, Math.min(1, channels[channel][i]));
      const int16 = sample < 0 ? sample * 0x8000 : sample * 0x7FFF;
      view.setInt16(offset + (i * blockAlign) + (channel * bytesPerSample), int16, true);
    }
  }

  return arrayBuffer;
}

function writeString(view: DataView, offset: number, string: string): void {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

function Upload() {
  const [title, setTitle] = useState('');
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const [duration, setDuration] = useState<number>(0);
  const [trimmer, setTrimmer] = useState<TrimmerPosition>({ start: 0, end: 30 });
  const [dragState, setDragState] = useState<DragState>({
    isDragging: false,
    startX: 0,
    initialStart: 0,
    initialEnd: 0,
    type: null,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const [isTrimming, setIsTrimming] = useState(false);

  useEffect(() => {
    if (waveformRef.current && audioFile) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: 'rgba(255, 255, 255, 0.3)',
        progressColor: 'rgba(255, 255, 255, 0.8)',
        cursorColor: 'transparent',
        barWidth: 2,
        barGap: 3,
        height: 60,
      });

      const audioUrl = URL.createObjectURL(audioFile);
      wavesurferRef.current.load(audioUrl);

      // Add volume change listener
      const handleVolumeChange = (e: CustomEvent) => {
        if (wavesurferRef.current) {
          wavesurferRef.current.setVolume(e.detail.volume);
        }
      };

      window.addEventListener('volume-change', handleVolumeChange as EventListener);

      wavesurferRef.current.on('ready', () => {
        const audioDuration = wavesurferRef.current!.getDuration();
        setDuration(audioDuration);
        setTrimmer({
          start: 0,
          end: Math.min(30, audioDuration),
        });
      });

      wavesurferRef.current.on('play', () => setIsPlaying(true));
      wavesurferRef.current.on('pause', () => setIsPlaying(false));
      wavesurferRef.current.on('finish', () => setIsPlaying(false));

      return () => {
        wavesurferRef.current?.destroy();
        URL.revokeObjectURL(audioUrl);
        window.removeEventListener('volume-change', handleVolumeChange as EventListener);
      };
    }
  }, [audioFile]);

  useEffect(() => {
    if (wavesurferRef.current) {
      wavesurferRef.current.on('interaction', () => {
        const currentTime = wavesurferRef.current!.getCurrentTime();
        // Keep playback within trimmed region
        if (currentTime < trimmer.start || currentTime > trimmer.end) {
          wavesurferRef.current!.seekTo(trimmer.start / duration);
        }
      });
    }
  }, [trimmer, duration]);

  useEffect(() => {
    if (wavesurferRef.current) {
      // Add audioprocess event listener to check current time
      const handleAudioProcess = () => {
        const currentTime = wavesurferRef.current!.getCurrentTime();
        if (currentTime >= trimmer.end) {
          wavesurferRef.current!.pause();
          wavesurferRef.current!.seekTo(trimmer.start / duration);
        }
      };

      wavesurferRef.current.on('audioprocess', handleAudioProcess);

      // Clean up listener
      return () => {
        wavesurferRef.current?.un('audioprocess', handleAudioProcess);
      };
    }
  }, [trimmer.end, trimmer.start, duration]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type.startsWith('audio/')) {
        setAudioFile(file);
        setError('');
      } else {
        setError('Please upload an audio file');
        setAudioFile(null);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsUploading(true);

    try {
      const user = auth.currentUser;
      if (!user || !audioFile) {
        throw new Error('Please login and select a file');
      }

      setIsTrimming(true);
      const trimmedBlob = await trimAudio(audioFile, trimmer.start, trimmer.end);
      setIsTrimming(false);

      // Create a new File from the trimmed blob
      const trimmedFile = new File([trimmedBlob], audioFile.name, {
        type: audioFile.type,
      });

      // Update the fetch URL to use API_URL
      const userResponse = await fetch(`${API_URL}/api/users/${user.uid}`);
      if (!userResponse.ok) {
        throw new Error('Failed to fetch user data');
      }
      const userData = await userResponse.json();

      const formData = new FormData();
      formData.append('title', title);
      formData.append('audioFile', trimmedFile);
      formData.append('userId', userData.id);

      // Update the fetch URL to use API_URL
      const response = await fetch(`${API_URL}/api/songs`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload song');
      }

      navigate('/');
    } catch (err: any) {
      console.error('Upload error:', err);
      setError(err.message || 'Failed to upload song');
    } finally {
      setIsUploading(false);
      setIsTrimming(false);
    }
  };

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      if (isPlaying) {
        wavesurferRef.current.pause();
      } else {
        wavesurferRef.current.seekTo(trimmer.start / duration);
        wavesurferRef.current.play();
      }
    }
  };

  const handleMouseDown = (e: React.MouseEvent, type: 'handle' | 'region') => {
    if (!containerRef.current) return;
    
    e.preventDefault(); // Prevent text selection
    const rect = containerRef.current.getBoundingClientRect();
    
    setDragState({
      isDragging: true,
      startX: e.clientX,
      initialStart: trimmer.start,
      initialEnd: trimmer.end,
      type,
    });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!dragState.isDragging || !containerRef.current) return;

    // Prevent text selection during drag
    e.preventDefault();

    const rect = containerRef.current.getBoundingClientRect();
    const pixelsPerSecond = rect.width / duration;
    const deltaX = (e.clientX - dragState.startX) / pixelsPerSecond;

    if (dragState.type === 'region') {
      // Moving the entire region
      let newStart = Math.max(0, dragState.initialStart + deltaX);
      let newEnd = Math.min(duration, dragState.initialEnd + deltaX);
      
      // If we hit the left boundary, clamp to 0
      if (newStart <= 0) {
        newStart = 0;
        newEnd = trimmer.end - trimmer.start; // Maintain width
      }

      // If we hit the right boundary, clamp to duration
      if (newEnd >= duration) {
        newEnd = duration;
        newStart = duration - (trimmer.end - trimmer.start); // Maintain width
      }

      // Only update if we're within bounds
      if (newEnd <= duration && newStart >= 0) {
        setTrimmer({ start: newStart, end: newEnd });
      }
    } else if (dragState.type === 'handle') {
      // Adjusting the end handle
      const newEnd = Math.min(
        duration,
        Math.max(
          trimmer.start + 1, // Minimum 1 second
          dragState.initialEnd + deltaX
        )
      );

      // Ensure we don't exceed 30 seconds and maintain minimum 1 second
      if (newEnd > trimmer.start && newEnd - trimmer.start <= 30) {
        setTrimmer(prev => ({ ...prev, end: newEnd }));
      }
    }
  };

  const handleMouseUp = () => {
    if (dragState.isDragging) {
      setDragState({
        isDragging: false,
        startX: 0,
        initialStart: 0,
        initialEnd: 0,
        type: null,
      });
    }
  };

  // Add this useEffect to handle edge cases
  useEffect(() => {
    // Ensure trimmer values are always valid
    if (trimmer.start < 0) {
      setTrimmer(prev => ({ ...prev, start: 0 }));
    }
    if (trimmer.end > duration) {
      setTrimmer(prev => ({ ...prev, end: duration }));
    }
    if (trimmer.end - trimmer.start > 30) {
      setTrimmer(prev => ({ ...prev, end: prev.start + 30 }));
    }
  }, [trimmer.start, trimmer.end, duration]);

  // Add this useEffect to handle mouse up globally
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (dragState.isDragging) {
        setDragState(prev => ({ ...prev, isDragging: false }));
      }
    };

    // Add global mouse event listeners
    window.addEventListener('mouseup', handleGlobalMouseUp);
    window.addEventListener('mouseleave', handleGlobalMouseUp);

    return () => {
      // Clean up listeners
      window.removeEventListener('mouseup', handleGlobalMouseUp);
      window.removeEventListener('mouseleave', handleGlobalMouseUp);
    };
  }, [dragState.isDragging]);

  const waveformContainer = (
    <div 
      ref={containerRef}
      style={{
        position: 'relative',
        height: '80px',
        marginTop: '20px',
        marginBottom: '25px',
        userSelect: 'none',
        touchAction: 'none',
        cursor: dragState.isDragging ? 'grabbing' : 'default',
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div ref={waveformRef} style={{ height: '100%', zIndex: 1 }} />
      
      <div style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-60%)',
        left: 0,
        right: 0,
        height: '100px',
        zIndex: 2
      }}>
        <TrimmerOverlay
          sx={{
            left: `${(trimmer.start / duration) * 100}%`,
            width: `${((trimmer.end - trimmer.start) / duration) * 100}%`,
          }}
          onMouseDown={(e) => handleMouseDown(e, 'region')}
        />
        <TrimmerHandle
          sx={{ 
            left: `${(trimmer.end / duration) * 100}%`,
            transform: 'translateX(0)',
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
            handleMouseDown(e, 'handle');
          }}
        />
      </div>

      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          bottom: -25,
          left: `${(trimmer.start / duration) * 100}%`,
          color: 'white',
          transform: 'translateX(-50%)',
          zIndex: 3
        }}
      >
        {trimmer.start.toFixed(1)}s
      </Typography>
      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          bottom: -25,
          left: `${(trimmer.end / duration) * 100}%`,
          color: 'white',
          transform: 'translateX(-50%)',
          zIndex: 3
        }}
      >
        {trimmer.end.toFixed(1)}s
      </Typography>
    </div>
  );

  return (
    <div className="upload-container">
      <form className="upload-form" onSubmit={handleSubmit}>
        <h2>Upload Song</h2>
        {error && <p style={{ color: 'red', fontSize: '0.8em' }}>{error}</p>}
        
        <div className="input-group" style={{ gap: '20px' }}>
          <input
            type="text"
            placeholder="Song Title *"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{
              flex: 1,
              height: '45px',
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              border: 'none',
              borderRadius: '4px',
              padding: '0 15px',
              color: 'white',
              fontSize: '16px',
            }}
          />
          
          <Button
            component="label"
            variant="outlined"
            sx={{
              height: '45px',
              color: 'white',
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              border: 'none',
              minWidth: '120px',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                border: 'none',
              },
            }}
          >
            CHOOSE FILE
            <input
              type="file"
              accept="audio/*"
              required
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </Button>
        </div>

        {audioFile && (
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.7)',
              mt: 1,
              ml: 1 
            }}
          >
            Selected: {audioFile.name}
          </Typography>
        )}

        {audioFile && (
          <div style={{ 
            position: 'relative',
            padding: '20px',
            minHeight: '160px',
            display: 'flex',
            flexDirection: 'column',
            background: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '12px',
            marginBottom: '20px',
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBottom: '20px',
              zIndex: 2,
            }}>
              <Button 
                variant="contained"
                onClick={handlePlayPause}
                sx={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  background: 'linear-gradient(45deg, #c084fc, #db2777)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #db2777, #c084fc)',
                  },
                  minWidth: 'unset',
                  padding: 0,
                  marginRight: '15px',
                }}
              >
                {isPlaying ? (
                  <PauseIcon sx={{ fontSize: 30 }} />
                ) : (
                  <PlayArrowIcon sx={{ fontSize: 30 }} />
                )}
              </Button>

              <div>
                <div style={{ 
                  fontSize: '16px', 
                  fontWeight: 'bold',
                  color: 'white'
                }}>
                  {audioFile.name}
                </div>
              </div>
            </div>

            {waveformContainer}
          </div>
        )}

        <Button
          type="submit"
          variant="contained"
          disabled={isUploading || isTrimming}
          startIcon={isUploading || isTrimming ? <CircularProgress size={20} color="inherit" /> : <CloudUploadIcon />}
          sx={{
            width: '100%',
            padding: '12px',
            background: 'linear-gradient(45deg, #c084fc, #db2777)',
            fontSize: '16px',
            fontWeight: 500,
            textTransform: 'none',
            borderRadius: '8px',
            '&:hover': {
              background: 'linear-gradient(45deg, #db2777, #c084fc)',
              transform: 'translateY(-2px)',
              boxShadow: '0 5px 15px rgba(219, 39, 119, 0.3)',
            },
            '&:disabled': {
              background: 'linear-gradient(45deg, #c084fc88, #db277788)',
              color: 'white',
            },
            transition: 'all 0.3s ease',
          }}
        >
          {isTrimming ? 'Trimming...' : isUploading ? 'Uploading...' : 'Upload Song'}
        </Button>
      </form>
    </div>
  );
}

export default Upload;
